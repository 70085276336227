import { querySelector } from 'utils/dom/querySelector';
import './Splash.css';

export class Splash {
  private element = querySelector<HTMLDivElement>('.kata-splash');
  private player = querySelector<any>('lottie-player');

  public fade() {
    this.element.style.transition = '0.3s';
    this.element.style.opacity = '0';
    this.element.style.visibility = 'hidden';
    this.player.stop();
  }

  public error(err: string) {
    const message = querySelector<HTMLDivElement>('.kata-splash__error-details');
    message.innerText = err;

    this.element.classList.add('kata-splash--hasError');
  }
}

export default Splash;
