/* eslint-disable import/no-extraneous-dependencies */
import get from 'lodash-es/get';

/**
 * Fallback environment variable.
 */
export const defaultEnvs: { [key: string]: string } = {
  REACT_APP_RUNTIME_WHATSAPP_SERVICE_URL: 'https://api-wa.katalabs.io',
  REACT_APP_RUNTIME_WHATSAPP_DASHBOARD_URL: 'http://wa-dashboard.katalabs.io/',
  REACT_APP_RUNTIME_MAINTENANCE: 'false',
  REACT_APP_RUNTIME_SENTRY_DSN: 'https://47b00a1e7bd24a448c1244a0a38dd974@sentry.io/1800122',
  REACT_APP_RUNTIME_GA_TRACKING_CODE: 'UA-108806080-10',
  REACT_APP_RUNTIME_GA_USER_ID: '213269338',
  REACT_APP_RUNTIME_GA_TEST_MODE: 'true',

  // flag for bizdev
  REACT_APP_RUNTIME_WITH_WHATSAPP_NUMBERS_BUSINESS: 'true',
  REACT_APP_RUNTIME_WITH_MESSAGE_TEMPLATES_BUSINESS: 'true',
  REACT_APP_RUNTIME_WITH_NOTIFICATIONS_BUSINESS: 'true',
  REACT_APP_RUNTIME_WITH_CLOUD_API_BIZDEV: 'true',

  // flag for client
  REACT_APP_RUNTIME_WITH_WHATSAPP_NUMBERS_CLIENT: 'true',
  REACT_APP_RUNTIME_WITH_MESSAGE_TEMPLATES_CLIENT: 'true',
  REACT_APP_RUNTIME_WITH_BROADCAST_CLIENT: 'true',
  REACT_APP_RUNTIME_WITH_BROADCAST_SCHEDULER_CLIENT: 'true',
  REACT_APP_RUNTIME_WITH_SUBSCRIPTION_CLIENT: 'true',
  REACT_APP_RUNTIME_WITH_NOTIFICATIONS_CLIENT: 'true',
  REACT_APP_RUNTIME_WITH_CLOUD_API_CLIENT: 'true',
  REACT_APP_RUNTIME_WITH_CLOUD_API_CLIENT_BIZDEV_APPROVE: 'true',
  REACT_APP_RUNTIME_WITH_CONTACT_CLIENT: 'true',
  REACT_APP_RUNTIME_WITH_CONTACT_AUDIENCE: 'true',
  REACT_APP_RUNTIME_WITH_CONTACT_CHATBOT_BUILDER: 'true',
  REACT_APP_RUNTIME_WITH_CONTACT_BUSINESS_INFORMATION: 'true',
  REACT_APP_RUNTIME_WITH_DASHBOARD_CLIENT: 'true',
  REACT_APP_RUNTIME_WITH_CHAT_WIDGET_CLIENT: 'true',

  // flag for main features
  REACT_APP_RUNTIME_WITH_CLIENT_REGISTER: 'true',
  REACT_APP_RUNTIME_WITH_FORGOT_PASSWORD: 'true',

  // flag for on premise
  REACT_APP_RUNTIME_IS_ON_PREMISE: 'false',

  // flag for captcha
  REACT_APP_RUNTIME_WITH_CAPTCHA: 'true',
  REACT_APP_RUNTIME_RECAPTCHA_SECRET: '6Ldx5z8UAAAAAKDtEYz7LzSXs21f30MIYoreLL5u',

  // topup balance
  REACT_APP_RUNTIME_WITH_TOPUP_BALANCE: 'true',
  REACT_APP_RUNTIME_WITH_ANALYTICS: 'true',

  // members
  REACT_APP_RUNTIME_WITH_MEMBERS: 'true',

  REACT_APP_RUNTIME_KATA_WEBSITE_URL: 'https://kata.ai/',
  REACT_APP_RUNTIME_KATA_BOOST_URL: 'https://boost.kata.ai/',
  REACT_APP_RUNTIME_FACEBOOK_MANAGER_APP_URL: 'https://www.facebook.com/business/help/2058515294227817',
  REACT_APP_RUNTIME_FACEBOOK_MANAGER_ID_URL: 'https://www.facebook.com/business/help/1612355968988019',
  REACT_APP_RUNTIME_WHATSAPP_TERM_URL: 'https://www.whatsapp.com/legal/FB-terms-whatsapp-business/',
  REACT_APP_RUNTIME_CREATE_MESSAGE_TEMPLATES_URL: 'https://developers.facebook.com/docs/whatsapp/message-templates',
  REACT_APP_RUNTIME_FACEBOOK_SETTING_URL: 'https://business.facebook.com/settings/requests',
  REACT_APP_RUNTIME_KATA_WHATSAPP_DOC_URL: 'https://katawhatsapp.docs.apiary.io/#reference/0/message/send-message',
  REACT_APP_RUNTIME_RECIPIENT_FILE_URL:
    'https://docs.google.com/spreadsheets/d/1RqA30VYNatx8uvAj7txQr0ayyZDxwHpAfP0R4otSh8E/edit?usp=sharing',

  REACT_APP_RUNTIME_WITH_BROADCAST_CSAT: 'true',
  REACT_APP_RUNTIME_MAX_LIMIT_WHATSAPP_NUMBER: '10',
  REACT_APP_RUNTIME_TIME_DELAY_NOTIFICATION: '900000',

  REACT_APP_RUNTIME_WITH_IMAGER: 'false',
  REACT_APP_RUNTIME_IMAGER_URL: 'https://imager-v2.katalabs.io',

  REACT_APP_RUNTIME_FE_SERVER_URL: 'http://localhost:8080/api',
  REACT_APP_RUNTIME_KATA_ALLOWED_DOMAIN:
    'http://localhost:3000,https://platform.katalabs.io,https://genai-middleware.katalabs.io,https://ipapi.co',
  REACT_APP_RUNTIME_WITH_WAEMBEDDED: 'true',
  REACT_APP_RUNTIME_WITH_TRIAL_EXPERIENCE: 'true',
  REACT_APP_RUNTIME_FACEBOOK_APPID: '1137731514102413',
  REACT_APP_RUNTIME_DEFAULT_SIZE_PAYLOAD: '10',
  REACT_APP_RUNTIME_FE_SERVER_URL_FILE: 'http://localhost:8080/files',
  REACT_APP_RUNTIME_WITH_UPLOAD_IMAGE_TEMPLATE: 'true',
  REACT_APP_RUNTIME_STRIPE_KEY: 'pk_test_Al0pE61E8YutPuk2H5c41kN600g1Y0szi5',
  REACT_APP_RUNTIME_CREDITS_LIMIT_BUY_MINIMUM: '100000',
  REACT_APP_RUNTIME_SALES_EMAIL: 'sales@kata.ai',
  REACT_APP_RUNTIME_WITH_SELF_PAYMENT: 'true',
  REACT_APP_RUNTIME_LIMIT_HEADER_IMAGE: '1572864',
  REACT_APP_RUNTIME_LIMIT_HEADER_MEDIA: '16777216',
  REACT_APP_RUNTIME_EXPIRED_DATE_BANNER_PRICING: '2022-2-1',
  REACT_APP_RUNTIME_DOCS_PRICING_URL: 'https://docs.kata.ai/business-dashboard/conversation-based-pricing',
  REACT_APP_RUNTIME_GTM_ID: 'GTM-MFX5M6B',
  REACT_APP_RUNTIME_METABASE_SITE_URL: 'https://metabase.kata.ai/',
  REACT_APP_RUNTIME_METABASE_SECRET_KEY: 'b6b3f0f8bd6b4cd28d869bfe45af0f8ad6fc3a46ab5a58abd4851d0630980f0d',
  REACT_APP_RUNTIME_BROADCAST_RECIPIENT_COST: '500',
  REACT_APP_RUNTIME_BROADCAST_RECIPIENT_UTILITY_COST: '321',
  REACT_APP_RUNTIME_BROADCAST_RECIPIENT_AUTHENTICATION_COST: '431',
  REACT_APP_RUNTIME_BROADCAST_RECIPIENT_MARKETING_COST: '657',
  REACT_APP_RUNTIME_BROADCAST_RECIPIENT_SERVICE_COST: '306',
  REACT_APP_RUNTIME_GEN_AI_MIDDLEWARE: 'https://genai-middleware.katalabs.io/v1',
  REACT_APP_RUNTIME_FE_GEN_AI_MIDDLEWARE: 'http://localhost:8080/middleware',
  REACT_APP_RUNTIME_AUDIENCE_LIMIT_PER_REQUEST: '1000',
  REACT_APP_RUNTIME_AUDIENCE_MAX_CONTACT: '10000',

  REACT_APP_RUNTIME_FILE_LIMIT_BROADCAST_CSV_RECIPIENT: '100',
  REACT_APP_RUNTIME_PRICE_PER_CREDIT: '99',
  REACT_APP_RUNTIME_QIOS_PAYMENT_URL: 'https://qios-payment.katalabs.io/v1',
  REACT_APP_RUNTIME_QIOS_MERCHANT_ID: '2aff4fc9-8adf-4b36-af92-6d54a481baf6',
  REACT_APP_RUNTIME_FAQ_TEMPLATE_FILE_URL:
    'https://docs.google.com/spreadsheets/d/1a3LR9Dz8StW1p-jS_oZOSUcdDajayFtSg-m1UiJ2c4Q/edit?usp=sharing',
  REACT_APP_RUNTIME_GEN_AI_MIDDLEWARE_NO_AUTH: 'https://genai-middleware.katalabs.io',

  REACT_APP_RUNTIME_LIMIT_HEADER_BROADCAST_IMAGE: '1572864',
  REACT_APP_RUNTIME_LIMIT_HEADER_BROADCAST_DOC: '1572864',
  REACT_APP_RUNTIME_LIMIT_HEADER_BROADCAST_MEDIA: '16777216',
  REACT_APP_RUNTIME_PACKAGE_PRICE_BASIC: '2500000',
  REACT_APP_RUNTIME_PACKAGE_PRICE_BASIC_ANUALLY: '2000000',
  REACT_APP_RUNTIME_PACKAGE_PRICE_BASIC_BEFORE_DISCOUNT: '4000000',
  REACT_APP_RUNTIME_PACKAGE_PRICE_BASIC_BEFORE_DISCOUNT_ANUALLY: '4000000',
  REACT_APP_RUNTIME_PACKAGE_PRICE_GROWTH: '6000000',
  REACT_APP_RUNTIME_PACKAGE_PRICE_GROWTH_ANUALLY: '5000000',
  REACT_APP_RUNTIME_PACKAGE_PRICE_GROWTH_BEFORE_DISCOUNT: '10000000',
  REACT_APP_RUNTIME_PACKAGE_PRICE_GROWTH_BEFORE_DISCOUNT_ANUALLY: '10000000',

  REACT_APP_RUNTIME_AUTH_SERVICE: 'https://auth.katalabs.io',
  REACT_APP_RUNTIME_FE_AUTH_SERVICE: 'http://localhost:8080/auth',
  REACT_APP_RUNTIME_OTP_COOKIES_DAY_EXPIRED: '30',

  REACT_APP_RUNTIME_TIME_DELAY_POLLING_TRAIN_CHATBOT: '30000',
  REACT_APP_RUNTIME_PAYMENT_INTERVAL_TYPE: 'MONTH',
  REACT_APP_RUNTIME_PAYMENT_INTERVAL_COUNT: '1',
  REACT_APP_RUNTIME_PAYMENT_ANUALLY_INTERVAL_COUNT: '12',
  REACT_APP_RUNTIME_GEN_AI_MIDDLEWARE_DEMO: 'https://genai-middleware.katalabs.io',
  REACT_APP_RUNTIME_FE_GEN_AI_MIDDLEWARE_DEMO: 'http://localhost:8080/demobot',
  REACT_APP_RUNTIME_LOCALIZATION_PUBLIC_URL: 'https://ipapi.co/json',
  REACT_APP_RUNTIME_FE_LOCALIZATION_PUBLIC_URL: 'http://localhost:8080/localization',
  REACT_APP_RUNTIME_FE_PAYMENT_URL: 'http://localhost:8080/payment',

  REACT_APP_RUNTIME_PRICE_PER_CREDIT_USD: '0.1',
  REACT_APP_RUNTIME_BROADCAST_RECIPIENT_COST_USD: '500',
  REACT_APP_RUNTIME_BROADCAST_RECIPIENT_UTILITY_COST_USD: '321',
  REACT_APP_RUNTIME_BROADCAST_RECIPIENT_AUTHENTICATION_COST_USD: '431',
  REACT_APP_RUNTIME_BROADCAST_RECIPIENT_MARKETING_COST_USD: '657',
  REACT_APP_RUNTIME_BROADCAST_RECIPIENT_SERVICE_COST_USD: '306',
  REACT_APP_RUNTIME_PACKAGE_PRICE_BASIC_USD: '299',
  REACT_APP_RUNTIME_PACKAGE_PRICE_BASIC_ANUALLY_USD: '249',
  REACT_APP_RUNTIME_PACKAGE_PRICE_BASIC_BEFORE_DISCOUNT_USD: '499',
  REACT_APP_RUNTIME_PACKAGE_PRICE_BASIC_BEFORE_DISCOUNT_ANUALLY_USD: '499',
  REACT_APP_RUNTIME_PACKAGE_PRICE_GROWTH_USD: '399',
  REACT_APP_RUNTIME_PACKAGE_PRICE_GROWTH_ANUALLY_USD: '349',
  REACT_APP_RUNTIME_PACKAGE_PRICE_GROWTH_BEFORE_DISCOUNT_USD: '665',
  REACT_APP_RUNTIME_PACKAGE_PRICE_GROWTH_BEFORE_DISCOUNT_ANUALLY_USD: '665',

  REACT_APP_RUNTIME_PACKAGE_SUPPORT_URL: 'https://kataassist.freshdesk.com/support/tickets/new',
  REACT_APP_RUNTIME_WIDGET_PLUGIN_JS: 'http://localhost:3000/widget/dev.widget.min.js',
};

/**
 * Loads environment variable set at runtime.
 *
 * @param name key of environment variable to load
 * @param defaultValue fallback value
 */
export function getRuntimeEnv(name: string, defaultValue?: string) {
  return get(window, ['__KATA_RUNTIME__', name], defaultValue || defaultEnvs[name] || undefined);
}

/**
 * Loads build-time environment variables through `process.env`.
 *
 * @param name key of environment variable to load
 * @param defaultValue fallback value
 */
export function getReactEnv(name: string, defaultValue?: string) {
  return get(process.env, name, defaultValue || defaultEnvs[name] || undefined);
}
