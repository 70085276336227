/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable prefer-const */
/* eslint-disable func-names */
/* eslint-disable no-console */
import * as env from 'utils/env';
import { Splash } from './modules/core/splash';

import 'typeface-inter';
import 'assets/fonts/museo-sans/museo-sans-rounded.css';
import 'assets/fonts/kata-icons/kata-icons.css';
import 'assets/fonts/sf-pro-display/sf-pro-display.css';
import 'assets/fonts/sf-pro-text/sf-pro-text.css';

const FACEBOOK_APPID = env.getRuntimeEnv(
  'REACT_APP_RUNTIME_FACEBOOK_APPID',
  env.defaultEnvs['REACT_APP_RUNTIME_FACEBOOK_APPID'],
);

async function initFacebookSdk() {
  return new Promise(() => {
    // wait for facebook sdk to initialize before starting the react app
    window.fbAsyncInit = function () {
      window.FB.init({
        appId: FACEBOOK_APPID, // Facebook App ID
        cookie: true, // enable cookies
        xfbml: true, // parse social plugins on this page
        version: 'v18.0', // Graph API version
      });
    };

    // load facebook sdk script
    (function (d, s, id) {
      let js: any;
      const fjs: any = d.getElementsByTagName(s)[0];
      if (d.getElementById(id)) {
        return;
      }
      js = d.createElement(s);
      js.id = id;
      js.src = 'https://connect.facebook.net/en_US/sdk.js';
      fjs.parentNode.insertBefore(js, fjs);
    })(document, 'script', 'facebook-jssdk');
  });
}

async function init() {
  const splash = new Splash();

  try {
    initFacebookSdk();
    const boot = await import('./boot');
    await boot.start();
    splash.fade();
  } catch (err) {
    splash.error(err);
    console.error(err.stack || err);
  }
}

init().catch(err => {
  console.error('Failed to load app', err);
});
